import React from 'react';
import { Box, Button, Center, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Center h="100vh">
      <Box textAlign="center">
        <Heading as="h1" size="xl" mb={6}>
          404 - Page Not Found
        </Heading>
        <p>The page you are looking for does not exist.</p>
        <Button colorScheme="orange" size="lg" mt={6}>
          <Link
            to="/dashboard"
            style={{ textDecoration: 'none', color: 'white' }}
          >
            Go to Dashboard
          </Link>
        </Button>
      </Box>
    </Center>
  );
};

export default NotFound;
