import React from 'react';
import { Spinner } from '@chakra-ui/react';

const Loader = ({ style }) => {
  return (
    <div
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
      }}
    >
      <Spinner size="xl" color="blue.500" />
    </div>
  );
};

export default Loader;
