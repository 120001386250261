import { createContext, useContext, useMemo } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
export const AxiosContext = createContext(undefined);

export default function AxiosProvider({ children }) {
  const navigate = useNavigate();
  const axios = useMemo(() => {
    const axios = Axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
    });
    axios.interceptors.request.use(async config => {
      let token = localStorage.getItem('adminToken');
      if (token) {
        config.headers.Authorization = token;
      } else {
        navigate('/login');
      }
      return config;
    });

    return axios;
  }, [navigate, localStorage.getItem('adminToken')]);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}

export function useAxios() {
  return useContext(AxiosContext);
}
