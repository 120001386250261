import React from 'react';
import TopHeader from '../../components/TopHeader';
import SidePanel from '../../components/LeftPanel';

export default function CustomerData() {
  return (
    <div className="flex flex-col h-screen ">
      <TopHeader title={'Customer Data'} />
      <div
        className="flex items justify-content h-full"
        style={{ marginTop: '9vh', marginLeft: '20vw' }}
      >
        <SidePanel current="Customer Data" />
        CustomerData
      </div>
    </div>
  );
}
