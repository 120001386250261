import React from 'react';
import { ChakraProvider, Box, theme } from '@chakra-ui/react';
import Routes from './Routes';
import { AuthProvider } from './common/provider/AuthProvider';
import AxiosProvider from './common/provider/AxiosProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

const aueryClient = new QueryClient();

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AxiosProvider>
        <QueryClientProvider client={aueryClient}>
          <AuthProvider>
            <Box textAlign="center" fontSize="xl">
              <Routes />
            </Box>
          </AuthProvider>
        </QueryClientProvider>
      </AxiosProvider>
    </ChakraProvider>
  );
}

export default App;
