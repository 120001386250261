import React from 'react';
import TopHeader from '../../components/TopHeader';
import SidePanel from '../../components/LeftPanel';
import ServiceProviderTable from './components/ServiceProviderTable';

export default function ServiceProviderData() {
  return (
    <div className="flex flex-col h-screen ">
      <TopHeader title={'All user data'} />
      <div
        className="flex items justify-content h-full"
        style={{ marginTop: '9vh', marginLeft: '20vw' }}
      >
        <SidePanel current="Service Provider Data" />
        <ServiceProviderTable />
      </div>
    </div>
  );
}
