import {
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputRightAddon,
  Input,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import {
  flexRender,
  useReactTable,
  getCoreRowModel,
} from '@tanstack/react-table';
import useGetVendor from '../../../common/api-hook/useGetVendor';
import useApproveOrRejectVendorById from '../../../common/api-hook/useApproveOrRejectVendorById';
import useGetAdminMetaData from '../../../common/api-hook/useGetAdminMetaData';
import Loader from '../../../components/Loader';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { GrNext, GrPrevious, GrFilter } from 'react-icons/gr';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import { useNavigate } from 'react-router-dom';
import { GrPowerReset } from 'react-icons/gr';
import { LiaSearchSolid } from 'react-icons/lia';
import GlobalLoader from '../../../components/GlobalLoader';
import { FaUserTimes, FaUserCheck } from 'react-icons/fa';

const ServiceProviderTable = () => {
  const navigate = useNavigate();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedCategoryName, setSelectedCategoryName] = useState('Category');
  const [typeName, setTypeName] = useState('Type');
  const [businessNameSearch, setBusinessNameSearch] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [openApprovelReject, setOpenApprovelReject] = useState({
    type: '',
    isOpen: false,
    vendorId: '',
  });
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const {
    data: vendorsData,
    isLoading: isVendorsLoading,
    isRefetching: isVendorsRefetching,
    refetch,
  } = useGetVendor(page, pageSize, {
    categoryId: selectedCategoryId,
    businessServiceRole: selectedType,
    isApproved: true,
    isActive: true,
    isDeleted: false,
    businessName: businessNameSearch,
    step: 6,
  });

  const { data: adminMetaData, isLoading: isAdminMetaDataLoading } =
    useGetAdminMetaData();

  const {
    mutateAsync: approveOrRejectVendorById,
    isLoading: isApproveOrRejectVendorByIdLoading,
  } = useApproveOrRejectVendorById();

  const getRelativeTime = date => {
    const now = dayjs();
    const diffInDays = now.diff(date, 'day');
    if (diffInDays === 0) {
      return 'Today';
    } else if (diffInDays === 1) {
      return 'Yesterday';
    } else {
      return date.format('DD/MM/YYYY');
    }
  };

  const finalData = useMemo(() => vendorsData?.vendors, [vendorsData]);
  const finalColumns = useMemo(
    () => [
      {
        accessorKey: 'businessName',
        header: 'Business Name',
      },
      {
        accessorKey: 'phoneNumber',
        header: 'Phone Number',
      },
      {
        accessorKey: 'email',
        header: 'Email ID',
      },
      {
        accessorKey: 'businessServiceRole',
        header: 'Type',
        cell: ({ row }) => {
          return (
            <>
              {row.original?.businessServiceRole
                ? row.original?.businessServiceRole?.charAt(0).toUpperCase() +
                  row.original?.businessServiceRole?.slice(1)
                : ''}
            </>
          );
        },
      },
      {
        accessorKey: 'categoryId',
        header: 'Category',
        cell: ({ row }) => {
          return <>{row.original?.categoryId?.name}</>;
        },
      },
      {
        header: 'Overall ratings',
        cell: () => {
          return <>5</>;
        },
      },
      {
        accessorKey: 'createdAt',
        header: 'Registered On',
        cell: ({ row }) => {
          return (
            <>
              {row.original?.createdAt
                ? getRelativeTime(dayjs(row.original?.createdAt))
                : ''}
            </>
          );
        },
      },
      {
        header: 'Actions',
        cell: ({ row }) => {
          return (
            <div>
              {row.original.isSuspended ? (
                <Tooltip label="Revoke Suspension">
                  <IconButton
                    isRound={true}
                    variant="solid"
                    fontSize="20px"
                    icon={<FaUserCheck color="#65BF83" />}
                    onClick={async () => {
                      setOpenApprovelReject({
                        type: 'revokeSuspension',
                        isOpen: true,
                        vendorId: row.original._id,
                      });
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip label="Suspend">
                  <IconButton
                    isRound={true}
                    variant="solid"
                    fontSize="20px"
                    icon={<FaUserTimes color="#DB0D4B" />}
                    onClick={async () => {
                      setOpenApprovelReject({
                        type: 'suspend',
                        isOpen: true,
                        vendorId: row.original._id,
                      });
                    }}
                  />
                </Tooltip>
              )}

              <Button
                h="1.75rem"
                size="sm"
                backgroundColor={'#fff'}
                onClick={() => {
                  navigate(`/vendor/${row.original._id}`, {
                    state: {
                      vendor: row.original,
                      mode: 'view',
                    },
                  });
                }}
              >
                View profile
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const [value, setValue] = useState('');

  const handleOnChange = event => {
    setValue(event.target.value);
  };
  const searchFunction = () => {
    setBusinessNameSearch(value);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => searchFunction(), 1000);
    return () => clearTimeout(timeoutId);
  }, [value]);

  const handlePageChange = newPage => {
    setPage(newPage);
    refetch();
  };

  const handleNextPage = () => {
    handlePageChange(page + 1);
  };

  const handlePreviousPage = () => {
    handlePageChange(page - 1);
  };

  const tableInstance = useReactTable({
    columns: finalColumns,
    data: finalData,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="w-full h-full p-3 text-3xl bg-slate-100">
      <div className="flex items-center justify-between">
        <div className="my-5 text-xl bg-white border cursor-pointer rounded-2xl border-gray w-fit">
          <HStack>
            <HStack className="px-5 py-2">
              <GrFilter size={18} />
              <Text fontWeight={500} fontFamily={'sans-serif'}>
                Filter By
              </Text>
            </HStack>
            <HStack className="px-5 py-2 border-collapse cursor-pointer border-gray border-x">
              <Menu>
                <MenuButton
                  transition="all 0.2s"
                  _focus={{ border: 'none', bg: 'white' }}
                >
                  <HStack>
                    <Text>{selectedCategoryName}</Text>
                    <IoIosArrowDown />{' '}
                  </HStack>
                </MenuButton>
                <MenuList zIndex={'99999'}>
                  <MenuItem
                    onClick={() => {
                      setSelectedCategoryName('Category');
                      setSelectedCategoryId('');
                    }}
                  >
                    Category
                  </MenuItem>
                  {adminMetaData?.category?.map(_ => {
                    return (
                      <>
                        <MenuItem
                          onClick={event => {
                            setSelectedCategoryId(event.target.value);
                            setSelectedCategoryName(_.name);
                            setPage(1);
                          }}
                          value={_._id}
                        >
                          {_.name}
                        </MenuItem>
                      </>
                    );
                  })}
                </MenuList>
              </Menu>
            </HStack>
            <HStack className="px-5 py-2 cursor-pointer">
              <Menu>
                <MenuButton
                  transition="all 0.2s"
                  _focus={{ border: 'none', bg: 'white' }}
                >
                  <HStack>
                    <Text> {typeName} </Text> <IoIosArrowDown />{' '}
                  </HStack>
                </MenuButton>
                <MenuList
                  zIndex={'99999'}
                  onChange={event => {
                    setSelectedType(event.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setSelectedType('');
                      setPage(1);
                      setTypeName('Type');
                    }}
                    value={'type'}
                  >
                    Type
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSelectedType('vendor');
                      setPage(1);
                      setTypeName('Vendor');
                    }}
                    value={'vendor'}
                  >
                    Vendor
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSelectedType('freelancer');
                      setPage(1);
                      setTypeName('Freelancer');
                    }}
                    value={'freelancer'}
                  >
                    Freelancer
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
            <HStack
              onClick={() => {
                setSelectedType('');
                setSelectedCategoryId('');
                setSelectedCategoryName('Category');
                setTypeName('Type');
                setPage(1);
              }}
              className="px-5 py-2 text-red-600 border-l border-collapse cursor-pointer border-gray"
            >
              <GrPowerReset />
              <Text fontWeight={500} fontFamily={'sans-serif'}>
                {' '}
                Clear Filter{' '}
              </Text>
            </HStack>
          </HStack>
        </div>
        <div>
          <InputGroup background={'white'}>
            <Input
              onChange={e => handleOnChange(e)}
              on
              placeholder="Search"
              border={'2px solid lightgray'}
            />
            <InputRightAddon
              background={'white'}
              borderRight={'2px solid lightgray'}
              borderTop={'2px solid lightgray'}
              borderBottom={'2px solid lightgray'}
            >
              <LiaSearchSolid />
            </InputRightAddon>
          </InputGroup>
        </div>
      </div>

      {isVendorsLoading && <Loader />}
      {isVendorsRefetching && <GlobalLoader />}

      {finalData && vendorsData.vendors && (
        <Style>
          <div
            style={{
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <div class="tableFixHead">
              <table>
                <thead>
                  {tableInstance.getHeaderGroups().map(headerElement => {
                    return (
                      <tr key={headerElement.id}>
                        {headerElement.headers.map(columnElement => {
                          return (
                            <th
                              kry={columnElement.id}
                              style={{
                                textAlign: 'start',
                                paddingLeft: '10px',
                                height: 40,
                              }}
                            >
                              {flexRender(
                                columnElement.column.columnDef.header,
                                columnElement.getContext()
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody>
                  {tableInstance.getRowModel().rows.map(rowElement => {
                    return (
                      <tr
                        key={rowElement.id}
                        style={{
                          backgroundColor: '#F0F0F0',
                          borderBottom: '1px solid lightgray',
                        }}
                      >
                        {rowElement.getVisibleCells().map(idata => {
                          return (
                            <td
                              key={idata.id}
                              style={{
                                fontSize: '17px',
                                textAlign: 'start',
                                paddingLeft: '10px',
                                paddingTop: '6px',
                                paddingBottom: '6px',
                              }}
                            >
                              {flexRender(
                                idata.column.columnDef.cell,
                                idata.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {!isVendorsLoading && vendorsData?.vendors?.length === 0 && (
                <NoDataComponent />
              )}
            </div>
          </div>
          <div>
            <IconButton
              size={'sm'}
              onClick={handlePreviousPage}
              isDisabled={page === 1}
              variant="solid"
              fontSize="20px"
              icon={<GrPrevious color="orange" />}
            />
            {Array(Math.ceil(vendorsData?.totalCount / pageSize))
              .fill()
              .map((_, index) => {
                return (
                  <IconButton
                    size={'sm'}
                    variant="solid"
                    fontSize="20px"
                    icon={<>{index + 1}</>}
                    backgroundColor={index + 1 === page ? '#FF7F3E' : ''}
                    onClick={() => handlePageChange(index + 1)}
                    isDisabled={index + 1 === page}
                  />
                );
              })}

            <IconButton
              size={'sm'}
              variant="solid"
              fontSize="20px"
              icon={<GrNext color="#65BF83" />}
              onClick={handleNextPage}
              isDisabled={
                Math.ceil(vendorsData?.totalCount / pageSize) === page ||
                isVendorsLoading
              }
            />
          </div>
        </Style>
      )}
      <Modal
        closeOnOverlayClick={false}
        isOpen={openApprovelReject.isOpen}
        onClose={() => setOpenApprovelReject({ type: '', isOpen: false })}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Alert </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {openApprovelReject.type === 'revokeSuspension'
              ? 'Conform to revoke suspension of this user.'
              : 'Conform to suspend this user.'}
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={isApproveOrRejectVendorByIdLoading}
              backgroundColor="#65BF83"
              mr={3}
              onClick={async () => {
                await approveOrRejectVendorById({
                  vendorId: openApprovelReject.vendorId,
                  isSuspended: !(
                    openApprovelReject.type === 'revokeSuspension'
                  ),
                });

                setOpenApprovelReject({
                  type: '',
                  isOpen: false,
                  vendorId: '',
                });
                refetch();
              }}
            >
              Conform
            </Button>

            <Button
              onClick={() => setOpenApprovelReject({ type: '', isOpen: false })}
              isDisabled={isApproveOrRejectVendorByIdLoading}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

const Style = styled.div`
  .tableFixHead {
    overflow: auto;
    height: 75vh;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    padding: 6px 6px;
  }
`;

export default ServiceProviderTable;
