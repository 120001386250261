import React from 'react';
import { Box, Text, Flex, Icon } from '@chakra-ui/react';
import { AiOutlineInbox } from 'react-icons/ai';

const NoDataComponent = () => {
  return (
    <Box
      p="4"
      border="1px solid #E2E8F0"
      borderRadius="lg"
      boxShadow="md"
      bg="white"
      textAlign="center"
    >
      <Flex direction="column" align="center" justify="center">
        <Icon as={AiOutlineInbox} color="gray.400" fontSize="4xl" mb="4" />
        <Text fontSize="lg" color="gray.600" mb="2">
          No data found
        </Text>
        <Text fontSize="sm" color="gray.400">
          There is no data available to display.
        </Text>
      </Flex>
    </Box>
  );
};

export default NoDataComponent;
