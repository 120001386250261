import React from 'react';
import TopHeader from '../../components/TopHeader';
import SidePanel from '../../components/LeftPanel';

export default function Review() {
  return (
    <div className="flex flex-col h-screen ">
      <TopHeader title={'Reviews'} />
      <div
        className="flex items justify-content h-full"
        style={{ marginTop: '9vh', marginLeft: '20vw' }}
      >
        <SidePanel current="Reviews" />
        Review
      </div>
    </div>
  );
}
