import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LogIn from './pages/LogIn/LogIn';
import Dashboard from './pages/Dashboard/Dashboard';
import { useAuth } from './common/provider/AuthProvider';
import NotFound from './pages/NotFound/NotFound';
import Approvals from './pages/Approvals';
import ServiceProviderData from './pages/ServiceProviderData';
import CustomerData from './pages/CustomerData';
import Review from './pages/Review';
import VendorProfileView from './pages/VendorProfileView';


const AppRouter = () => {
  const { isAuthenticated } = useAuth();
  return (
    <Routes>
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/dashboard" /> : <LogIn />}
      />
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/dashboard"
        element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
      />
      <Route
        path="/approvals"
        element={isAuthenticated ? <Approvals /> : <Navigate to="/login" />}
      />
      <Route
        path="/service-provider-data"
        element={
          isAuthenticated ? <ServiceProviderData /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/customer-data"
        element={isAuthenticated ? <CustomerData /> : <Navigate to="/login" />}
      />
      <Route
        path="/reviews"
        element={isAuthenticated ? <Review /> : <Navigate to="/login" />}
      />
      <Route
        path="/vendor/:vendorId"
        element={isAuthenticated ? <VendorProfileView /> : <Navigate to="/login" />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
