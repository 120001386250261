import React from 'react';
import {
  HStack,
  Text,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';
//   import Image from 'next/image'
import { IoIosArrowDown } from 'react-icons/io';
import { useAuth } from '../../common/provider/AuthProvider';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const Header = ({ title }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const menuButton = () => {
    return (
      <Menu>
        <MenuButton
          px={2}
          py={2}
          transition="all 0.2s"
          borderRadius="50"
          borderWidth="1px"
          _hover={{ bg: 'gray.400' }}
          _expanded={{ bg: 'blue.400' }}
          _focus={{ boxShadow: 'outline' }}
        >
          <IoIosArrowDown />
        </MenuButton>
        <MenuList>
          <MenuItem>Account</MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={() => {
              logout();
              navigate('/login');
            }}
          >
            Log out
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };
  return (
    <Style>
      <div className="header">
        <Text
          fontFamily={'sans-serif'}
          fontWeight={600}
          fontSize={'35px'}
          textAlign={'start'}
        >
          {title}
        </Text>
        <HStack>
          <img
            src={
              // './assets/sampleUser.svg'
              'https://storage.googleapis.com/my-event-mate-dev-bucket/vendorProfilePictures/logoCut2.jpg'
            }
            width={45}
            height={45}
          />
          <Text fontFamily={'sans-serif'} fontWeight={'600'}>
            {localStorage.getItem('userName').charAt(0).toUpperCase() +
              localStorage.getItem('userName').slice(1)}
          </Text>
          {menuButton()}
        </HStack>
      </div>
    </Style>
  );
};

const Style = styled.div`
  .header {
    position: fixed;
    top: 0;
    left: 20vw;
    height: 9vh;
    width: 80vw;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 1000;
  }
`;

export default Header;
