import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Heading,
  Center,
  useToast,
  HStack,
  Text,
} from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { SHA256 } from 'crypto-js';
import { useAuth } from '../../common/provider/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const { login, isSignInLoading } = useAuth();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();

  const handleSubmit = async event => {
    event.preventDefault();
    if (!userName || !password) {
      toast({
        title: 'Invalid Data',
        description: 'Please enter username and password',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    const hashedPassword = SHA256(password).toString();
    try {
      await login({ userName, password: hashedPassword });
      navigate('/dashboard');
    } catch (err) {
      console.error(err);
    }
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div
      style={{
        backgroundImage: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
      }}
    >
      <Center h="100vh">
        <Box
          p={8}
          maxW={'100%'}
          minW={'35%'}
          borderWidth={1}
          borderRadius={8}
          boxShadow="lg"
          background={'white'}
        >
          <Stack spacing={4}>
            <div className="flex items-center justify-between">
              <Text color={'#025F72'} fontSize={'28px'} fontWeight={600}>
                Admin LogIn
              </Text>
              <img src={'./assets/memLogo3.svg'} height={60} width={120} />
            </div>
            <form onSubmit={handleSubmit}>
              <FormControl id="email" isRequired>
                <FormLabel>User Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter User Name"
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    <Button
                      h="1.75rem"
                      size="sm"
                      variant="ghost"
                      colorScheme="orange"
                      onClick={handleTogglePasswordVisibility}
                    >
                      {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                colorScheme="orange"
                type="submit"
                mt={8}
                isFullWidth
                isLoading={isSignInLoading}
                width={'50%'}
                backgroundImage={
                  'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)'
                }
              >
                Sign in
              </Button>
            </form>
          </Stack>
        </Box>
      </Center>
    </div>
  );
};

export default Login;
