import { Badge, HStack, Text } from '@chakra-ui/react';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';

const CategoryBadgeBox = ({
  children,
  selected = false,
  color,
  bgColor,
  ...props
}) => {
  return (
    <motion.div layout>
      <Badge
        padding={'4'}
        paddingY={'1'}
        lineHeight={'6'}
        borderRadius={'full'}
        backgroundColor={bgColor}
        color={color}
        {...props}
      >
        <HStack alignItems={'center'}>
          {selected && (
            <motion.span layout>
              <IoCheckmarkOutline color="#fff" size={18} />
            </motion.span>
          )}

          <motion.div layout>
            <Text fontWeight={'400'} textTransform={'capitalize'}>
              {children}
            </Text>
          </motion.div>
        </HStack>
      </Badge>
    </motion.div>
  );
};

export default CategoryBadgeBox;
