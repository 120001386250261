import React, { createContext, useContext, useEffect } from 'react';
import useLogIn from '../api-hook/useLogIn';
import { useToast } from '@chakra-ui/react';
import useGetUserSession from '../api-hook/useGetUserSession';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { mutateAsync: logIn, isLoading: isSignInLoading } = useLogIn();
  const {
    data: userSession,
    isError: isUserSessionErr,
    error: userSessionError,
  } = useGetUserSession();

  const toast = useToast();
  let isAuthenticated = localStorage.getItem('isAdminLogIn');
  useEffect(() => {
    let adminToken = localStorage.getItem('adminToken');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    isAuthenticated = localStorage.getItem('isAdminLogIn');
    if (isAuthenticated && adminToken && userSession) {
      if (userSession?.error) {
        logout();
      } else {
        isAuthenticated = true;
        localStorage.setItem('adminUserId', userSession?.userId);
      }
    } else {
      isAuthenticated = false;
    }
  }, [userSession]);
  console.log('userSession', userSession);
  useEffect(() => {
    if (userSessionError && isUserSessionErr) {
      toast({
        title: 'User Session Error',
        description: userSessionError,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isAuthenticated = false;
      localStorage.setItem('adminToken', undefined);
      localStorage.setItem('isAdminLogIn', false);
      localStorage.setItem('userName', undefined);
    }
  }, [isUserSessionErr, toast, userSessionError]);

  const login = async ({ userName, password }) => {
    try {
      const userLogIn = await logIn({ userName, password });
      console.log('dddd', userLogIn?.token);
      localStorage.setItem('adminToken', userLogIn?.token);
      localStorage.setItem('userName', userName);
      localStorage.setItem('isAdminLogIn', true);
      isAuthenticated = true;
      toast({
        title: 'LogIn ',
        description: 'Success',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } catch (err) {
      localStorage.setItem('adminToken', undefined);
      localStorage.setItem('isAdminLogIn', false);
      isAuthenticated = false;
      toast({
        title: 'LogIn Error',
        description: err?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return err;
    }
  };

  const logout = () => {
    isAuthenticated = false;
    localStorage.setItem('adminToken', undefined);
    localStorage.setItem('isAdminLogIn', false);
    localStorage.setItem('userName', undefined);
  };

  const getToken = () => {
    if (localStorage.getItem('isAdminLogIn')) {
      return localStorage.getItem('isAdminLogIn');
    }
    return undefined;
  };

  const getUserSession = () => {
    return userSession;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: localStorage.getItem('isAdminLogIn') === 'true',
        login,
        logout,
        isSignInLoading,
        getToken,
        getUserSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
