import { useQuery } from 'react-query';
import { useAxios } from '../provider/AxiosProvider';

const GET_VENDOR = 'useGetVendor';

export default function useGetVendor(page, pageSize, query, enable = true) {
  const axios = useAxios();
  return useQuery(
    [GET_VENDOR, page, pageSize, query],
    async () => {
      const routePath = 'admin/get-vendor';
      const params = { page, pageSize, query };
      return axios
        ?.get(`${routePath}`, { params })
        .then(res => res.data)
        .catch(e => {
          return e.response.data;
        });
    },
    {
      staleTime: 60 * 1000,
      enabled: enable,
    }
  );
}
