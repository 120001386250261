import { Fragment, useEffect, useState } from 'react';
import CategoryBadgeBox from './categoryBadgeBox';
import { HStack } from '@chakra-ui/react';

const CategorySelector = ({
  onChange,
  data = [],
  value,
  isReadOnly = false,
}) => {
  return (
    <HStack flexWrap={'wrap'} id="subCategorySelctor" spacing={'5'}>
      {data.map(option => (
        <Fragment key={option._id}>
          {/* <input
            type="checkbox"
            id={option._id}
            value={option._id}
            readOnly={isReadOnly}
            style={
              isReadOnly
                ? {
                    cursor: 'default',
                  }
                : {}
            }
            onChange={e => {
              if (isReadOnly) {
                return;
              }
              if (e.target.checked && !value.includes(e.target.value)) {
                onChange([...value, e.target.value]);
              }

              if (!e.target.checked && value.includes(e.target.value)) {
                onChange(value.filter(item => item !== e.target.value));
              }
            }}
          /> */}
          <label
            className={isReadOnly ? 'cursor-default' : 'cursor-pointer'}
            htmlFor={option._id}
          >
            <CategoryBadgeBox
              // color={value.includes(option._id) ? 'white' : 'primary.500'}
              // backgroundColor={
              //   value.includes(option._id) ? 'primary.500' : 'primary.50'
              // }
              color={'primary.500'}
              backgroundColor={'primary.500'}
              selected={value.includes(option._id)}
            >
              {option.name}
            </CategoryBadgeBox>
          </label>
        </Fragment>
      ))}
    </HStack>
  );
};

export default CategorySelector;
