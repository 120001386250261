import React from 'react';
import Header from '../../components/TopHeader';
import SidePanel from '../../components/LeftPanel';
import { Text } from '@chakra-ui/react';

const Dashboard = props => {
  const currentUser = localStorage.getItem('userName');
  const QIInnerDiv = 'flex items-start p-5 justify-between w-fit rounded-lg';
  return (
    <div
      className="flex flex-col h-screen "
      style={{ marginTop: '9vh', marginLeft: '20vw' }}
    >
      <Header title={'Dashboard'} />
      <div className="flex items-center justify-between h-full">
        <SidePanel current="Dashboard" />
        <div className="w-full h-full text-3xl bg-slate-100">
          <div
            style={{
              backgroundImage: 'url(/assets/admin_bg_removed.jpg)',
            }}
            className="flex items-center w-full p-10 bg-cover rounded-b-xl h-150 "
          >
            <div className="flex flex-col items-start">
              <Text color={'white'} fontFamily={'sans-serif'}>
                Welcome{' '}
                {currentUser
                  ? currentUser.charAt(0).toUpperCase() + currentUser.slice(1)
                  : ''}{' '}
                !
              </Text>
              <Text
                fontSize={'17px'}
                fontFamily={'sans-serif'}
                color={'white'}
                fontWeight={400}
              >
                You are one of the super admin of MEM.
              </Text>
            </div>
          </div>
          <div className="w-full h-full p-5 bg-slate-100">
            <div className="w-full py-8 bg-white px-9">
              {/* quickinsight div */}
              <div className="text-xl font-semibold text-start ">
                Quick Insights
              </div>

              <div className="flex items-center justify-between my-5">
                <div
                  style={{
                    boxShadow: '-1px -1px 10px -3px rgba(184,184,184,1)',
                    width: '300px',
                    height: '170px',
                  }}
                  className={QIInnerDiv}
                >
                  {' '}
                  {/* quick insight inside container 1/3 */}
                  <div className="flex flex-col items-start w-fit">
                    <Text fontSize={'15px'} fontWeight={500}>
                      Total Vendors
                    </Text>
                    <Text fontWeight={600}>48,298</Text>
                  </div>
                  <img src={'./assets/eyeIcon.svg'} width={60} height={60} />
                </div>
                <div
                  className={QIInnerDiv}
                  style={{
                    boxShadow: '-1px -1px 10px -3px rgba(184,184,184,1)',
                    width: '300px',
                    height: '170px',
                  }}
                >
                  {' '}
                  {/* quick insight inside container 2/3 */}
                  <div className="flex flex-col items-start w-fit">
                    <Text fontSize={'15px'} fontWeight={500}>
                      Total Freelancers
                    </Text>
                    <Text fontWeight={600}>48,298</Text>
                  </div>
                  <img src={'./assets/eyeIcon.svg'} width={60} height={60} />
                </div>
                <div
                  className={QIInnerDiv}
                  style={{
                    boxShadow: '-1px -1px 10px -3px rgba(184,184,184,1)',
                    width: '300px',
                    height: '170px',
                  }}
                >
                  {' '}
                  {/* quick insight inside container 3/3 */}
                  <div className="flex flex-col items-start w-fit">
                    <Text fontSize={'15px'} fontWeight={500}>
                      Total Customers
                    </Text>
                    <Text fontWeight={600}>48,298</Text>
                  </div>
                  <img src={'./assets/eyeIcon.svg'} width={60} height={60} />
                </div>
              </div>
            </div>
            <div className="w-full py-8 my-5 bg-white px-9">
              {' '}
              {/* line chart for views */}
              <Text
                fontSize={'18px'}
                fontWeight={600}
                fontFamily={'sans-serif'}
                textAlign={'start'}
              >
                Views in MEM
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../common/provider/AuthProvider';
// import LeftPanel from '../../components/LeftPanel';

// export default function Dashboard() {
//   const navigate = useNavigate();
//   const { logout } = useAuth();
//   return (
//     <div>
//       <LeftPanel current={'Dashboard'} />
//       <button
//         onClick={() => {
//           navigate('/profile');
//         }}
//       >
//         profile
//       </button>
//       <button
//         onClick={() => {
//           logout();
//           navigate('/login');
//         }}
//       >
//         Log Out
//       </button>
//       <div>Dashboard</div>
//     </div>
//   );
// }
