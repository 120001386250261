import { useQuery } from 'react-query';
import { useAxios } from '../provider/AxiosProvider';

const GET_ADMIN_META_DATA = 'getAdminMetaData';

export default function useGetAdminMetaData(enable = true) {
  const axios = useAxios();
  return useQuery(
    [GET_ADMIN_META_DATA],
    async () => {
      const routePath = 'admin/get-admin-meta-data';
      return axios
        ?.get(`${routePath}`)
        .then(res => res.data)
        .catch(e => {
          return e.response.data;
        });
    },
    {
      staleTime: Infinity,
      enabled: enable,
    }
  );
}
