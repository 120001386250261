import { useAxios } from '../provider/AxiosProvider';
import { useMutation } from 'react-query';

export default function useApproveOrRejectVendorById() {
  const axios = useAxios();

  return useMutation(async data =>
    axios
      ?.post('admin/approve-or-reject-vendor-by-id', data)
      .then(res => {
        return res.data;
      })
      .catch(e => {
        throw e.response.data;
      })
  );
}
