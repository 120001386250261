import React from 'react';
import TopHeader from '../../components/TopHeader';
import SidePanel from '../../components/LeftPanel';
import ApprovalsContent from './approvalsContent';

const Approvals = props => {
  return (
    <div className="flex flex-col h-screen ">
      <TopHeader title={'Approvals'} />
      <div
        className="flex items justify-content h-full"
        style={{ marginTop: '9vh', marginLeft: '20vw' }}
      >
        <SidePanel current="Approvals" />
        <ApprovalsContent />
      </div>
    </div>
  );
};

export default Approvals;
