import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import CategorySelector from './components/categorySelector';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/TopHeader';
import SidePanel from '../../components/LeftPanel';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { FaAngleLeft } from 'react-icons/fa';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import useApproveOrRejectVendorById from '../../common/api-hook/useApproveOrRejectVendorById';
import TopHeader from '../../components/TopHeader';

const rejectionReason = {
  'Images Not Found': 'Images is not found in your Profile',
  'Business Name': 'Your Business Name should be clear',
  Other: '',
};

export default function VendorProfileView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { vendor, mode = '' } = location.state;

  const [openApprovelReject, setOpenApprovelReject] = useState({
    type: '',
    isOpen: false,
    vendorId: '',
  });
  const [rejectionReasonKey, setRejectionReasonKey] = useState('');
  const [customRejectionReason, setCustomRejectionReason] = useState('');

  const {
    mutateAsync: approveOrRejectVendorById,
    isLoading: isApproveOrRejectVendorByIdLoading,
  } = useApproveOrRejectVendorById();

  const vendorDetails = vendor;
  const subCategories = vendorDetails.subCategoryId;

  console.log('vendorDetails', vendorDetails);

  const {
    register,
    reset,
    control,
    formState: { errors, isValid, submitCount },
    getValues,
  } = useForm();

  const [readOnly, setReadOnly] = useState(true);

  const toast = useToast();

  useEffect(() => {
    if (vendorDetails) {
      reset({
        ...vendorDetails,
        subCategoryId: vendorDetails.subCategoryId?.map(item => item._id),
        priceRangeMin: vendorDetails.priceDetails?.minAmount,
        priceRangeMax: vendorDetails.priceDetails?.maxAmount,
        isTravelToClientPlace: vendorDetails.isTravelToClientPlace
          ? 'yes'
          : 'no',
      });
    }
  }, [vendorDetails]);

  return (
    <div
      className="flex flex-col h-screen "
      style={{ marginTop: '9vh', marginLeft: '20vw' }}
    >
      <TopHeader title={''} />
      <div className="flex items-center justify-between h-full">
        <SidePanel
          current={mode !== 'view' ? 'Approvals' : 'Service Provider Data'}
        />
        <div className="w-full h-full text-3xl bg-slate-100">
          <div className="p-5">
            <div className="flex items-start gap-5">
              <div className="cursor-pointer" onClick={() => navigate(-1)}>
                {' '}
                <FaArrowLeftLong />
              </div>
              <Text
                as={'h4'}
                lineHeight={8}
                textAlign={'start'}
                fontSize={'32px'}
                fontWeight={'bold'}
                marginBottom={'15px'}
              >
                Profile
              </Text>
            </div>

            <Box
              py={'9'}
              px={'16'}
              position={'relative'}
              backgroundColor={'white'}
            >
              <HStack>
                <Text
                  as={'h4'}
                  lineHeight={8}
                  fontSize={'2xl'}
                  fontWeight={'bold'}
                >
                  Business Details
                </Text>
              </HStack>
              <form>
                <div className="cursor-pointer w-fit">
                  <img
                    src={'/assets/photoUpload.svg'}
                    width={105}
                    height={105}
                    alt="upload img"
                  />
                  <Text color={'orange'} fontSize={'18px'} fontWeight={'600'}>
                    Upload Photo
                  </Text>
                </div>

                <FormControl my={'42px'} maxW={'70%'}>
                  <FormLabel fontWeight={'semibold'}>Category</FormLabel>
                  <Select
                    width={'50%'}
                    {...register('businessServiceRole', {
                      required: {
                        message: 'Please select your category',
                        value: true,
                      },
                    })}
                    _disabled={{
                      cursor: 'default',
                      backgroundColor: 'white',
                      color: 'black',
                      borderColor: '#EDEDED',
                    }}
                    disabled={readOnly}
                  >
                    <option value={'freelancer'}>Freelancer</option>
                    <option value={'vendor'}>Vendor</option>
                  </Select>
                </FormControl>
                <HStack maxW={'70%'} className="my-[42px]">
                  <FormControl>
                    <FormLabel fontWeight={'semibold'}>Business Name</FormLabel>
                    <Input
                      width={'full'}
                      {...register('businessName', {
                        required: {
                          message: 'Please enter your business name',
                          value: true,
                        },
                      })}
                      isReadOnly={readOnly}
                      variant={'outline'}
                      placeholder="Business name"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight={'semibold'}>Phone Number</FormLabel>
                    <Input
                      width={'full'}
                      {...register('phoneNumber', {
                        required: {
                          message: 'Please enter your phone number',
                          value: true,
                        },
                        minLength: {
                          message: 'Please enter a valid phone number',
                          value: 10,
                        },
                        maxLength: {
                          message: 'Please enter a valid phone number',
                          value: 10,
                        },
                      })}
                      isReadOnly={true}
                      placeholder="Phone number"
                    />
                  </FormControl>
                </HStack>
                <FormControl maxW={'70%'} className="my-[42px]">
                  <FormLabel fontWeight={'semibold'}>Address</FormLabel>
                  <Textarea
                    {...register('address', {
                      required: {
                        message: 'Please enter your address',
                        value: true,
                      },
                    })}
                    isReadOnly={readOnly}
                  />
                </FormControl>
                <FormControl maxW={'70%'} className="my-[42px]">
                  <FormLabel fontWeight={'semibold'}>
                    Total years of service
                  </FormLabel>
                  <Controller
                    className="text-start"
                    control={control}
                    name="yearsOfExperience"
                    render={({ field: { value, onChange } }) => {
                      console.log('yearsOfExperienceValue', value);
                      return (
                        <Slider
                          min={0}
                          max={30}
                          step={1}
                          width={300}
                          marginTop={7}
                          value={value}
                          focusThumbOnChange={false}
                          onChange={e => {
                            onChange({
                              target: { name: 'yearsOfExperience', value: e },
                            });
                          }}
                          fontSize={'14px'}
                          padding={'0px'}
                          isReadOnly={readOnly}
                        >
                          <SliderTrack
                            borderRadius={'2xl'}
                            height={2}
                            bg="#E9ECF2"
                          >
                            <SliderFilledTrack bg="orange" />
                          </SliderTrack>
                          <SliderThumb
                            boxSize={6}
                            border="2px solid white "
                            bg="orange"
                          />

                          <SliderMark
                            textAlign="center"
                            bg="orange"
                            color="white"
                            borderRadius={50}
                            mt="-10"
                            ml="-4"
                            w="7"
                          >
                            {value}
                          </SliderMark>
                        </Slider>
                      );
                    }}
                    rules={{
                      required: {
                        message: 'Please enter your total years of service',
                        value: true,
                      },
                      validate: value => {
                        return value !== null
                          ? true
                          : 'Please enter your total years of service';
                      },
                    }}
                  />
                </FormControl>
                <div className="max-w-[70%] w-full my-[42px]">
                  <Text
                    className="my-7"
                    fontSize="15px"
                    fontWeight={'semibold'}
                    // fontFamily={'sans-serif'}
                    textAlign={'start'}
                    // letterSpacing={0.5}
                  >
                    Socials links
                  </Text>
                  <div>
                    {/* social media container */}
                    <HStack className="mb-4">
                      <img
                        width={35}
                        height={35}
                        src={'/assets/fb.svg'}
                        alt="faceBook logo"
                      />
                      <Input
                        size={'md'}
                        width={'50%'}
                        isReadOnly={readOnly}
                        placeholder="Facebook profile link"
                        {...register('socialMediaUrls.faceBook', {
                          pattern: {
                            value:
                              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                            message:
                              'Please enter a valid faceBook profile link',
                          },
                        })}
                      />
                    </HStack>
                    <HStack className="mb-4">
                      <img
                        src={'/assets/pintrest.jpg'}
                        width={35}
                        height={35}
                        alt="pintrest"
                      />

                      <Input
                        size={'md'}
                        width={'50%'}
                        placeholder="Pinterest profile link"
                        isReadOnly={readOnly}
                        {...register('socialMediaUrls.twitter', {
                          pattern: {
                            value:
                              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                            message:
                              'Please enter a valid pinterest profile link',
                          },
                        })}
                      />
                    </HStack>
                    <HStack className="mb-4">
                      <img
                        width={35}
                        height={35}
                        src={'/assets/insta.svg'}
                        alt="instagram logo"
                      />
                      <Input
                        size={'md'}
                        width={'50%'}
                        isReadOnly={readOnly}
                        {...register('socialMediaUrls.instagram', {
                          pattern: {
                            value:
                              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                            message:
                              'Please enter a valid instagram profile link',
                          },
                        })}
                        placeholder="Instagram profile link"
                      />
                    </HStack>
                    <HStack className="mb-4">
                      <img
                        width={35}
                        height={35}
                        src={'/assets/globe.svg'}
                        alt="webSite logo"
                      />
                      <Input
                        size={'md'}
                        width={'50%'}
                        isReadOnly={readOnly}
                        {...register('socialMediaUrls.webSite', {
                          pattern: {
                            value:
                              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                            message: 'Please enter a valid url',
                          },
                        })}
                        placeholder="Website link"
                      />
                    </HStack>
                  </div>
                </div>
                <FormControl maxW={'70%'} my={'42px'} marginBottom={'8'}>
                  <FormLabel
                    fontSize={'md'}
                    fontWeight={'600'}
                    lineHeight={'5'}
                  >
                    Business description
                  </FormLabel>
                  <Textarea
                    borderColor={'#DFDFDF'}
                    colorScheme="orange"
                    placeholder="Type here"
                    rows={4}
                    isReadOnly={readOnly}
                    maxW={'80%'}
                    {...register('businessDesc', {
                      required: {
                        value: true,
                        message: 'Please enter your business description',
                      },
                    })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize={'md'}
                    fontWeight={'600'}
                    lineHeight={'5'}
                  >
                    Business Images
                  </FormLabel>
                  <div
                    className="flex gap-10"
                    style={{
                      display: 'flex',
                      overflowX: 'scroll',
                      gap: 40,
                      width: '100%',
                      padding: '10px',
                      marginBottom: '25px',
                      border: '2px solid lightgray',
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px',

                      // borderTopRightRadius: '20px',
                      // borderRadius: '20px',
                    }}
                  >
                    {vendorDetails.businessImgUrls &&
                      Array.isArray(vendorDetails.businessImgUrls) &&
                      vendorDetails.businessImgUrls.length > 0 &&
                      vendorDetails.businessImgUrls.map(data => {
                        return (
                          <img
                            className="object-contain"
                            src={data}
                            width={90}
                            height={105}
                          />
                        );
                      })}
                  </div>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize={'md'}
                    fontWeight={'600'}
                    lineHeight={'5'}
                    marginBottom={'15'}
                  >
                    FAQs
                  </FormLabel>
                  <div>
                    {vendorDetails.faqs &&
                      Array.isArray(vendorDetails.faqs) &&
                      vendorDetails.faqs.length > 0 &&
                      vendorDetails.faqs.map(data => {
                        return (
                          <div
                            style={{
                              border: '1px solid lightgray',
                              borderRadius: '12px',
                              padding: '5px 10px',
                              marginBottom: '5px',
                            }}
                          >
                            <div className="text-xl text-start my-[5px]">
                              Q : {data.question}
                            </div>
                            <div className="text-sm text-gray-500 my-[5px] text-start">
                              Ans :{data.answer}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </FormControl>
                <FormControl my={'42px'}>
                  <FormLabel
                    fontSize={'md'}
                    fontWeight={'600'}
                    lineHeight={'5'}
                    marginBottom={'5'}
                  >
                    Providable services
                  </FormLabel>
                  {/* {isSubCategoryLoading && <p>Loading...</p>} */}
                  {subCategories && (
                    <Controller
                      control={control}
                      name="subCategoryId"
                      render={({ field: { onChange, value = [] } }) => (
                        <CategorySelector
                          isReadOnly={readOnly}
                          data={subCategories}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      rules={{
                        required: {
                          message: 'Please select at least one service',
                          value: true,
                        },
                        validate: value => {
                          return value.length > 0
                            ? true
                            : 'Please select at least one service';
                        },
                      }}
                    />
                  )}
                </FormControl>

                <FormControl my={'42px'}>
                  <FormLabel
                    fontSize={'md'}
                    fontWeight={'600'}
                    lineHeight={'5'}
                    marginBottom={'5'}
                  >
                    Price range
                  </FormLabel>
                  <HStack spacing={'8'}>
                    <Input
                      variant="filled"
                      bgColor={'#F9F9F9'}
                      width={'140px'}
                      height={'43px'}
                      isReadOnly={readOnly}
                      placeholder="min"
                      {...register('priceRangeMin', {
                        required: {
                          value: true,
                          message: 'Please enter your min price range',
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: 'Please enter a valid number',
                        },
                        validate: [
                          value => {
                            return (
                              parseInt(value) <=
                                parseInt(getValues('priceRangeMax')) ||
                              'Min price must be less than max price'
                            );
                          },
                        ],
                      })}
                    />
                    <Input
                      variant="filled"
                      bgColor={'#F9F9F9'}
                      width={'140px'}
                      isReadOnly={readOnly}
                      height={'43px'}
                      placeholder="max"
                      {...register('priceRangeMax', {
                        required: {
                          value: true,
                          message: 'Please enter your max price range',
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: 'Please enter a valid number',
                        },
                        validate: value => {
                          return (
                            parseInt(value) >
                              parseInt(getValues('priceRangeMin')) ||
                            'Max price must be greater than min price'
                          );
                        },
                      })}
                    />
                  </HStack>
                </FormControl>

                <FormControl marginBottom={'12'}>
                  <FormLabel
                    fontSize={'md'}
                    fontWeight={'600'}
                    lineHeight={'5'}
                    marginBottom={'5'}
                  >
                    Can travel to client place?
                  </FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RadioGroup value={value}>
                          <HStack spacing={'55px'} direction="row">
                            <Radio
                              isReadOnly={readOnly}
                              onChange={onChange}
                              value="yes"
                            >
                              Yes
                            </Radio>
                            <Radio
                              isReadOnly={readOnly}
                              onChange={onChange}
                              value="no"
                            >
                              No
                            </Radio>
                          </HStack>
                        </RadioGroup>
                      );
                    }}
                    name={'isTravelToClientPlace'}
                    control={control}
                    rules={{
                      required: {
                        message: 'Please select an option',
                        value: true,
                      },
                    }}
                  />
                </FormControl>
              </form>
              <div className="flex items-center justify-between">
                <Button
                  style={{
                    border: '1px solid #999999',
                    background: 'transparent',
                    color: '#999999',
                  }}
                  onClick={() => navigate(-1)}
                >
                  {' '}
                  <FaAngleLeft /> Back
                </Button>
                {mode !== 'view' && (
                  <HStack justifyContent={'flex-end'}>
                    <Button
                      onClick={async () => {
                        setOpenApprovelReject({
                          type: 'approve',
                          isOpen: true,
                          vendorId: vendor._id,
                        });
                      }}
                      colorScheme="gray"
                      minW={'180px'}
                      background={'#32de84'}
                      color={'white'}
                      _hover={{
                        background: '#32de84',
                        boxShadow: '-1px -2px 34px -11px rgba(50,222,132,1)',
                      }}
                    >
                      <IoIosCheckmarkCircleOutline
                        style={{
                          color: 'white',
                          fontSize: '25px',
                          background: '#32de84',
                        }}
                      />{' '}
                      Approve
                    </Button>
                    <Button
                      color={'white'}
                      _hover={{
                        background: '#EE204D',
                        boxShadow: '-1px -2px 34px -11px rgba(238,32,77,1)',
                      }}
                      // box-shadow: -1px -2px 34px -11px rgba(NaN,238,32,1);
                      onClick={() => {
                        setOpenApprovelReject({
                          type: 'reject',
                          isOpen: true,
                          vendorId: vendor._id,
                        });
                      }}
                      background={'#EE204D'}
                      colorScheme={''}
                      // variant={'outline'}
                      minW={'180px'}
                    >
                      Reject
                    </Button>
                  </HStack>
                )}
              </div>
            </Box>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openApprovelReject.isOpen}
        onClose={() => {
          setOpenApprovelReject({ type: '', isOpen: false, vendorId: '' });
          setRejectionReasonKey('');
          setCustomRejectionReason('');
        }}
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {openApprovelReject.type === 'approve' ? 'Approve' : 'Reject'}{' '}
            Vendor
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {openApprovelReject.type === 'approve' ? (
              <Text>Are you sure you want to approve this vendor?</Text>
            ) : (
              <>
                <Text>Are you sure you want to reject this vendor?</Text>
                <Select
                  placeholder="Select reason"
                  mt={4}
                  value={rejectionReasonKey}
                  onChange={e => setRejectionReasonKey(e.target.value)}
                >
                  {Object.keys(rejectionReason).map(reasonKey => (
                    <option key={reasonKey} value={reasonKey}>
                      {reasonKey}
                    </option>
                  ))}
                </Select>
                {rejectionReasonKey === 'Other' && (
                  <Textarea
                    type="text"
                    mt={4}
                    height={120}
                    placeholder="Enter customer rejection reason"
                    value={customRejectionReason}
                    onChange={e => setCustomRejectionReason(e.target.value)}
                  />
                )}
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setOpenApprovelReject({
                  type: '',
                  isOpen: false,
                  vendorId: '',
                });
                setRejectionReasonKey('');
                setCustomRejectionReason('');
              }}
            >
              Close
            </Button>
            {openApprovelReject.type === 'approve' ? (
              <Button
                colorScheme="green"
                onClick={async () => {
                  await approveOrRejectVendorById({
                    vendorId: openApprovelReject.vendorId,
                    isApproved: true,
                    isRejected: false,
                  });

                  setOpenApprovelReject({
                    type: '',
                    isOpen: false,
                    vendorId: '',
                  });
                  navigate('/approvals');
                }}
                isLoading={isApproveOrRejectVendorByIdLoading}
              >
                Approve
              </Button>
            ) : (
              <Button
                colorScheme="red"
                onClick={async () => {
                  const reason =
                    rejectionReasonKey === 'Other'
                      ? customRejectionReason
                      : rejectionReasonKey;

                  await approveOrRejectVendorById({
                    vendorId: openApprovelReject.vendorId,
                    isApproved: false,
                    isRejected: true,
                    rejectionReason: reason,
                  });

                  setOpenApprovelReject({
                    type: '',
                    isOpen: false,
                    vendorId: '',
                  });
                  setRejectionReasonKey('');
                  setCustomRejectionReason('');
                  navigate('/approvals');
                }}
                isLoading={isApproveOrRejectVendorByIdLoading}
                isDisabled={
                  rejectionReasonKey === '' ||
                  (rejectionReasonKey === 'Other' &&
                    customRejectionReason === '')
                }
              >
                Reject
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
