import React from 'react';
import { Progress, Box } from '@chakra-ui/react';

const GlobalLoader = () => (
  <Box width="100%">
    <Progress size="xs" isIndeterminate sp />
  </Box>
);

export default GlobalLoader;
