import { HStack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BsPersonCheck } from 'react-icons/bs';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { HiOutlineChartBarSquare } from 'react-icons/hi2';
import { FaPeopleRoof } from 'react-icons/fa6';
import { TbMailBolt } from 'react-icons/tb';

const SidePanel = props => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(props.current);

  const sideItems = [
    { icon: <HiOutlineChartBarSquare fontSize={'20px'} />, name: 'Dashboard' },
    { icon: <BsPersonCheck fontSize={'20px'} />, name: 'Approvals' },
    {
      icon: <FaPeopleRoof fontSize={'20px'} />,
      name: 'Service Provider Data',
    },
    { icon: <HiOutlineUserGroup fontSize={'20px'} />, name: 'Customer Data' },
    { icon: <TbMailBolt fontSize={'20px'} />, name: 'Reviews' },
  ];
  useEffect(() => {
    if (props.current) {
      setSelected(props.current);
    }
  }, [props]);
  const navigatorFunction = name => {
    if (name === 'Dashboard') {
      navigate('/dashboard');
    } else if (name === 'Approvals') {
      navigate('/approvals');
    } else if (name === 'Service Provider Data') {
      navigate('/service-provider-data');
    } else if (name === 'Customer Data') {
      navigate('/customer-data');
    } else if (name === 'Reviews') {
      navigate('/reviews');
    }
  };
  return (
    <Style>
      <div className="leftPannel">
        <img
          src={
            // './assets/memLogo3.svg'
            'https://storage.googleapis.com/my-event-mate-dev-bucket/vendorProfilePictures/memLogo3.svg'
          }
          height={60}
          width={120}
        />
        <div className="flex flex-col items-start justify-start">
          {sideItems.map(items => {
            return (
              <HStack
                className={
                  selected === items.name
                    ? 'w-full p-2'
                    : 'w-full p-2 cursor-pointer'
                }
                style={
                  selected === items.name
                    ? { backgroundColor: '#E5E4FF' }
                    : { backgroundColor: '#fff' }
                }
                onClick={() => navigatorFunction(items.name)}
              >
                {items.icon}
                <Text
                  fontFamily={'sans-serif'}
                  letterSpacing={'1px'}
                  fontSize={'18px'}
                >
                  {items.name}
                </Text>
              </HStack>
            );
          })}
        </div>
      </div>
    </Style>
  );
};

const Style = styled.div`
  .leftPannel {
    width: 20vw;
    height: 100vh;
    position: fixed;
    left: 0;
    bottom: 0;
  }
`;

export default SidePanel;
