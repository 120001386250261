import { useQuery } from 'react-query';
import { useAxios } from '../provider/AxiosProvider';

const GET_USER_SESSION = 'getUserSessiom';

export default function useGetUserSessiom(enable = true) {
  const axios = useAxios();
  return useQuery(
    [GET_USER_SESSION],
    async () => {
      const routePath = 'auth/get-user-session';
      return axios
        ?.get(`${routePath}`)
        .then(res => {
          return res.data;
        })
        .catch(e => {
          return e.response.data;
        });
    },
    {
      staleTime: 0,
      enabled: enable,
    }
  );
}
