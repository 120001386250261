// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-size: 18px;
  width: 100%;
  font-family: sans-serif;
  border-radius: 12px;
}
table th {
  background-color: #156172;
  color: #ffff;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Approvals/table.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["table {\n  font-size: 18px;\n  width: 100%;\n  font-family: sans-serif;\n  border-radius: 12px;\n}\ntable th {\n  background-color: #156172;\n  color: #ffff;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
